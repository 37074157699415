<!--
 * @Description: 文本,金额,数值,单选,右侧展示的关联接口,关联显示,辅助查看,辅助选择等
 * @Author: 梁平贤
 * @Date: 2020/7/28 09:32
 -->
<template>
  <div class="right-addition" v-show="rightAdditionButtons.length>0">
    <!--多个按钮需要做成弹出菜单-->
    <el-dropdown v-if="rightAdditionButtons.length > 1">
      <span class="el-dropdown-link">
        <en-icon class="icon-placeholder icon-more"
                 name="icongengduo-guanlian"
                 color="#4895DF"
                 size="18px">

        </en-icon>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="(data, index) in rightAdditionButtons"
                          @click.native="handleClick(data)"
                          :key="index">
          <!--ocr单独渲染input选文件-->
          <input v-if="isOcrType(data)"
                 ref="fileInput"
                 type="file"
                 name="avatar"
                 accept="image/jpeg,image/jpg,image/png"
                 @change="handleOcrClick"
                 v-show="false">
          <en-icon :name="buttonData[data].icon" :title="buttonData[data].name" :color="buttonData[data].color" size="18px" class="drop-icon">
          </en-icon>
          <span class="drop-name">{{buttonData[data].name}}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <!--单个按钮直接展示-->
    <en-icon v-else-if="rightAdditionButtons.length === 1"
             class="icon-placeholder"
             :name="buttonData[rightAdditionButtons[0]].icon"
             :title="buttonData[rightAdditionButtons[0]].name"
             :color="buttonData[rightAdditionButtons[0]].color"
             size="18px"
             @click.native.stop="handleClick(rightAdditionButtons[0])">
    </en-icon>
    <!--单个并且是OCR-->
    <input v-if="rightAdditionButtons.length === 1 && isOcrType(rightAdditionButtons[0])"
           ref="fileInput"
           type="file"
           name="avatar"
           accept="image/jpeg,image/jpg,image/png"
           @change="handleOcrClick" v-show="false">
    <!--需要用的到一些弹窗-->
    <AssociateShowDialog :dialog-visible.sync="associateDialog" :column="column" :row-index="rowIndex" :business-data="businessData"/>
    <summaryResolveDetail
      v-if="summaryVisible"
      :column="column"
      :business-data="businessData"
      :visible="summaryVisible"
      @close="summaryVisible = false"
    ></summaryResolveDetail>
    <RelevancyAdd v-if="linkColumn.editable&&column.originRelatedField" title="选择数据" :detailType='true' ref="linkDialog"  :column="linkColumn" :business-data="businessData"></RelevancyAdd>
    <!-- 手动关联添加 -->
    <RelevancyAdd :is-manual-add-relation="true" v-if="isManualAddRelation" :row-index="rowIndex" title="选择数据" ref="linkDialog"  :column="column" :business-data="businessData"></RelevancyAdd>
    <assist-select ref="assistDialog" :column="column" :business-data="businessData"></assist-select>
    <geo-map :visible.sync="addressGeo" @success="geoSuccess"></geo-map>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { publicService, loginService } from "@/api";
import API from "@/api/homeMenu";
import { businessService } from "@/api/businessModel";
import AssociateShowDialog from "./AssociateShowDialog";
import summaryResolveDetail from "./summaryResolveDetail";
import assistSelect from "./assistSelect";
import MxRightAddition, { RightAdditionButtonType } from "./MxRightAddition";
import RelevancyAdd from "../relevancyAdd/test.vue";
import MxInterfaceCommand from "./MxInterfaceCommad";

import GeoMap from "./geoMap";

export default {
  name: "AssociateAddition",
  mixins: [MxRightAddition, MxInterfaceCommand],
  components: {
    AssociateShowDialog, summaryResolveDetail, assistSelect, RelevancyAdd, GeoMap
  },
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
     // 明细第几行
    rowIndex: {
      type: Number,
      default: 0
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    linkColumn() {
      return this.column.originRelatedField ? this.column.findColumnByField(this.column.originRelatedField) : "";
    }
  },
  data() {
    return {
      // 关联显示的弹窗
      associateDialog: false,
      summaryVisible: false,
      addressGeo: false,

      // 按钮对应的数据
      buttonData: {
        [RightAdditionButtonType.assistSelect]: {
          icon: "iconfuzhuxuanze",
          name: "辅助选择",
          color: "#4895DF"
        },
        [RightAdditionButtonType.assistView]: {
          icon: "iconfuzhuchakan",
          name: "辅助查看",
          color: "#FFAD2C"
        },
        [RightAdditionButtonType.interfaceOther]: {
          icon: "iconqita-jiekou",
          name: "关联接口",
          color: "#4895DF"
        },
        [RightAdditionButtonType.interfaceSkyEye]: {
          icon: "iconqiyezhengxin-jiekou",
          name: "企业征信",
          color: "#4895DF"
        },
        [RightAdditionButtonType.interfaceOCR]: {
          icon: "iconOCR-jiekou",
          name: "OCR",
          color: "#4895DF"
        },
        [RightAdditionButtonType.associateShow]: {
          icon: "iconguanlianxianshi",
          name: "关联显示",
          color: "#A971F6"
        },
        [RightAdditionButtonType.rollUp]: {
          icon: "iconhuizongmingxi",
          name: "汇总明细",
          color: "#FFAD2C"
        },
        [RightAdditionButtonType.breakDown]: {
          icon: "iconfenjiemingxi",
          name: "分解明细",
          color: "#26C393"
        },
        [RightAdditionButtonType.relationAdd]: {
          icon: "iconguanlianshuju-ziji",
          name: "关联添加",
          color: "#26C393"
        },
        // [RightAdditionButtonType.addressMap]: {
        //   icon: "guanlianshuju-mingxi",
        //   name: "地图定位",
        //   color: "#26C393"
        // }, 地图图标多一个 注释
        [RightAdditionButtonType.singleSign]: {
          icon: "iconqiehuanfangshi-denglu",
          name: "单点登录",
          color: "#26C393"
        }
      }
    };
  },
  methods: {
    ...mapActions(["login", "logout", "saveUrlParam", "saveLoginAccount"]),
    ...mapMutations("main", ["setMenuData"]),
    // 按钮点击
    handleClick(type) {
      console.log("handleClick", type);
      switch (type) {
        case RightAdditionButtonType.assistSelect:
          this.$refs.assistDialog.handleClick();
          break;
        case RightAdditionButtonType.assistView:
          console.log(this.$parent);
          this.$emit("handleAssitViewClick");
          break;
        case RightAdditionButtonType.interfaceOther:
          this.handleOtherInterfaceButtonClick();
          break;
        case RightAdditionButtonType.interfaceSkyEye:
          this.handleEyeSkyButtonClick();
          break;
        case RightAdditionButtonType.interfaceOCR:
          this.triggerOcrCommand();
          break;
        case RightAdditionButtonType.associateShow:
          this.associateDialog = true;
          break;
        case RightAdditionButtonType.rollUp:
        case RightAdditionButtonType.breakDown:
          // 向上汇总和向下分解点击
          this.summaryVisible = true;
          break;
        case RightAdditionButtonType.relationAdd:
          // 关联数据添加
          console.log("关联添加", this.$refs.linkDialog);
          this.$refs.linkDialog.openDialog();
          break;
        case RightAdditionButtonType.addressMap:
          // 地图定位
          this.addressGeo = true;
          break;
        case RightAdditionButtonType.singleSign:
          // 单点登录
          this.mdSwitchAccount();
          break;
        default:
          break;
      }
    },
    async mdSwitchAccount() {
      try {
        this.loadingInstance = this.$enLoading("正在登录中...");
        const response = await businessService.mdSwitchAccount({
          templateId: this.businessData.templateId || this.$route.query.templateId,
          dataId: this.businessData.id,
          field: this.column.field
        });
        const res = await loginService.login({
          account: response.mobileNo,
          password: response.password
        });
        // 暂不支持管理端登录
        // if (res.isSysManager === "001" || res.isSysSuperManager === "001") {
        //   this.$message.warning("暂不支持管理员登录");
        //   return;
        // }
        this.login(res);
        this.$eventHub.$emit("changeUser");
        const urlConfig = await publicService.getUploadUrl();
        this.saveUrlParam(urlConfig);
        const data = await API.getMenu();
        this.setMenuData(data);
        this.loadingInstance.close();
        this.$router.replace("/home/index");
      } catch (error) {
        this.loadingInstance.close();
        console.log(error);
      }
    },
    geoSuccess(pois) {
      this.$emit("geoSuccess", pois);
    },
    // 是否是OCR
    isOcrType(type) {
      return type === RightAdditionButtonType.interfaceOCR;
    }

  }
};
</script>

<style scoped lang="scss">
  .drop-icon {
    vertical-align: middle;
  }
  .drop-name {
    vertical-align: middle;
    margin-left: 10px;
  }
  .right-addition {
    cursor: pointer;
    .icon-placeholder {
      margin-top: 4px;
    }
    .icon-more {
      top: 4px;
    }
  }
</style>
